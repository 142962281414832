import { CSSProperties } from "react";
import { AnalyticsEvent } from "./AnalyticsRecorder";

// Enables us to do inline styling
const noscriptAnalyticsStyling: CSSProperties = {
  display: "none",
  visibility: "hidden",
};

type Props = { analyticsEvent?: AnalyticsEvent };

export const HeadAnalytics: React.FC<Props> = (props) => {
  const googleTagManagerId = "GTM-K9QFVXJ";
  const googleAnalyticsId = "UA-35132796-3";
  const googleAdWordsId = "AW-666459267";
  const googleAnalytics4Id = "G-JV6275LNP9";
  const facebookIdentifer = "598069471038146";

  const trackableEnv = process.env.TRACK_ANALYTICS === "true";

  if (!trackableEnv) {
    return null;
  } else {
    return (
      <>
        {/* Google Tag Manager */}
        <script
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer',"${googleTagManagerId}");`,
          }}
        />
        {/* <noscript>
          <iframe
            src={`https://www.googletagmanager.com/ns.html?id=${googleTagManagerId}`}
            height="0"
            width="0"
            style={noscriptAnalyticsStyling}
          ></iframe>
        </noscript> */}

        {/* Global site tag (gtag.js) - Google Analytics */}
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`}
        ></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', "${googleAnalyticsId}");
          gtag('config', "${googleAdWordsId}");
          gtag('config', "${googleAnalytics4Id}");
        `,
          }}
        />

        {/* Facebook Pixel Code */}
        <script
          dangerouslySetInnerHTML={{
            __html: `!function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', "${facebookIdentifer}");
        fbq('track', 'PageView');`,
          }}
        />
        <noscript>
          <img
            height="1"
            width="1"
            style={noscriptAnalyticsStyling}
            src={`https://www.facebook.com/tr?id=${facebookIdentifer}&ev=PageView&noscript=1`}
          />
        </noscript>
      </>
    );
  }
};

type FooterProps = {};
export const FooterAnalytics: React.FC<FooterProps> = (props) => {
  const linkedInIdentifier = "1751578";

  const trackableEnv = process.env.TRACK_ANALYTICS === "true";

  if (!trackableEnv) {
    return <></>;
  }

  return (
    <>
      <script
        dangerouslySetInnerHTML={{
          __html: `_linkedin_partner_id = "${linkedInIdentifier}";
        window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
        window._linkedin_data_partner_ids.push(_linkedin_partner_id);
        </script><script type="text/javascript">
        (function(){var s = document.getElementsByTagName("script")[0];
        var b = document.createElement("script");
        b.type = "text/javascript";b.async = true;
        b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
        s.parentNode.insertBefore(b, s);})();`,
        }}
      />

      <noscript>
        <img
          height="1"
          width="1"
          style={noscriptAnalyticsStyling}
          src={`https://px.ads.linkedin.com/collect/?${linkedInIdentifier}&fmt=gif`}
        />
      </noscript>
    </>
  );
};
